<template>
  <van-empty align="center" image="error" description="We're sure you are lost. There is nothing here." />
</template>

<script>
export default {
  name: 'PageNotFound',
  metaInfo: {
    title: 'Page Not Found',
  },
};
</script>
